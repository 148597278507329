.filter_tr {
    background-color: $blue;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='12' viewBox='0 0 20 12'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='charlie-brown' fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath d='M9.8 12L0 2.2V.8l10 10 10-10v1.4L10.2 12h-.4zm-4 0L0 6.2V4.8L7.2 12H5.8zm8.4 0L20 6.2V4.8L12.8 12h1.4zM9.8 0l.2.2.2-.2h-.4zm-4 0L10 4.2 14.2 0h-1.4L10 2.8 7.2 0H5.8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.table_head_1 {
    color: #fff;
    background-color: #141416;
}

.modal-header {
    border-bottom: 0 !important;
}

.modal-footer {
    border-top: 0 !important;
}

.modal-content {
    border: 3px solid #eee !important;
}

.sidebar {
    background: #f8f8f8;
}

.feather {
    width: 16px;
    height: 16px;
}

.user-content {
    background: #f8f8f8;
}

.pagination__wrapper {
    border-top: 0;
}

a:hover {
    text-decoration: underline !important;
}

.nav .active {
    font-weight: bold;
}

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 180px !important;
}

.modal-content {
    background-color: #eeeeee;
    background-image: url("data:image/svg+xml,%3Csvg width='84' height='48' viewBox='0 0 84 48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h12v6H0V0zm28 8h12v6H28V8zm14-8h12v6H42V0zm14 0h12v6H56V0zm0 8h12v6H56V8zM42 8h12v6H42V8zm0 16h12v6H42v-6zm14-8h12v6H56v-6zm14 0h12v6H70v-6zm0-16h12v6H70V0zM28 32h12v6H28v-6zM14 16h12v6H14v-6zM0 24h12v6H0v-6zm0 8h12v6H0v-6zm14 0h12v6H14v-6zm14 8h12v6H28v-6zm-14 0h12v6H14v-6zm28 0h12v6H42v-6zm14-8h12v6H56v-6zm0-8h12v6H56v-6zm14 8h12v6H70v-6zm0 8h12v6H70v-6zM14 24h12v6H14v-6zm14-8h12v6H28v-6zM14 8h12v6H14V8zM0 8h12v6H0V8z' fill='%23ffffff' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
}

.header-icon_search_custom:before {
    position: absolute;
    left: -20px;
    top: 1px;
    content: '';
    background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 15.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='28px' height='28px' viewBox='11 11 28 28' enable-background='new 11 11 28 28' xml:space='preserve'%3E%3Cg%3E%3Cpath d='M30.426,28.502c1.429-1.839,2.289-4.141,2.289-6.645C32.715,15.87,27.844,11,21.857,11S11,15.87,11,21.857 s4.87,10.858,10.857,10.858c2.185,0,4.216-0.654,5.921-1.769 M12.8,21.857c0-4.994,4.063-9.057,9.057-9.057 c4.995,0,9.057,4.063,9.057,9.057c0,4.995-4.062,9.057-9.057,9.057C16.863,30.914,12.8,26.852,12.8,21.857z'/%3E%3Cpath d='M27.778,30.946L35.832,39l2.545-2.545l-7.951-7.953'/%3E%3C/g%3E%3C/svg%3E%0A") top left no-repeat;
    width: 27px;
    height: 30px;
    display: inline-block;
    @include extra-small-mobile {
        display: none;
    }
}

.small-icon {
    height: 35px;
}

.modal {
    z-index: 99999999 !important;
}

.filter-box {
    max-height: 20rem;
    min-height: 5rem;
    overflow: scroll;
}

.product_helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.product_figure {
    white-space: nowrap;
}

.product_image_link {
    vertical-align: middle;
}

.product_image_link img:hover {
    -webkit-filter: brightness(110%);
}

.custom-search-input {
    margin-left: 5px !important;
}

strong {
    font-weight: bold;
}

.pagination-sm .page-link {
    padding: .63rem 0rem !important;
}

.badge {
    font-size: 0.7rem !important;
}

.badge-primary, .btn-primary {
    color: #fff !important;
}

.grid_item_special {
    border: 1px solid #F4F4F4;
    border-radius: 15px;
}

.product_image_link img {
    margin-left: -3px;
}

.no-pointer {
    cursor: auto !important;
}

html {
    background-color: #eee !important;
}

.bg-soft-grey {
    background-color: $bg-color-2;
}

.bg-soft-h {
    background-color: $bg-color-4;
}

@media (min-width: 1024px) {
    .figure_rectangle {
        height: 200px;
    }

    .figure_portrait {
        height: 250px;
    }

    .figure_rectangle_ {
        height: 330px;
    }

    .figure_portrait_ {
        height: 380px;
    }
}

.faq-items {
    border-bottom: 1px solid #eee;
    padding-bottom: 6px;
    margin-bottom: 5px;
}

.box_news:hover {
    text-decoration: none !important;
}

.home-border {
    border: 6px solid #fff;
}

/*
.b_shift_right ul li a {
    margin-right: 6px;
}

.b_shift_right ul li span {
    margin-right: 6px;
}

.b_shift_first ul li a:first-child {
    margin-right: 6px;
}
*/

.container_home {
    border-radius: 15px;
}

.home-border {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.no-hover:hover {
    text-decoration: none !important;
}

.small-article-box {
    margin-bottom: 30px;
    /*border-top: 1px dashed #eee;
    border-bottom: 1px dashed #eee;*/
    padding-top: 20px;
}

.round-me {
    border-radius: 15px !important;
}

.round-me-top {
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
}

.round-me-bottom {
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
}

.faq h3, .post-content h3 {
    font-weight: bold;
}

.filter_col a {
    color: #777;
}

.filter-box-border-left {
    border-left: 1px dashed #eee;
}

.filter-box-input {
    padding: 5px;
    width: 100%;
    border: 1px dashed#eee;
    border-bottom: none;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    outline: none;
}

.filter-box-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color-1;
    opacity: 1; /* Firefox */
}

.filter-box-input::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color-1;
}

.filter-box-input::-ms-input-placeholder { /* Microsoft Edge */
    color: $color-1;
}

.products-holder {
    border: 5px solid $color-1;
    padding: 15px;
    border-radius: 15px;
}
