@import 'variables';
@import 'mixin';
/*============================================================================================*/
/* ERROR AND TRACK ORDER PAGE */
/*============================================================================================*/
#error_page, #newsletter {
	width: 100%;
	//height: 500px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	p {
		@include fontSize(21px);
		@include mobile {
			@include fontSize(18px);
		}
	}
}
.newsletter_input {
	position: relative;
	margin-bottom: 60px;
	input[type='text'] {
		border: 0;
		height: 50px;
		@include border-radius(3px);
		padding-left: 15px;
		@include box-shadow (0px 0px 50px 0px rgba(0, 0, 0, 0.15));
	}
	input[type='submit'] {
		position: absolute;
		right: -1px;
		color: #fff;
		font-weight: 600;
		top: 0;
		border: 0;
		padding: 0 25px;
		height: 50px;
		cursor: pointer;
		@include border-radius(0 3px 3px 0);
		background-color: $color-1;
		@include transition-default;
		&:focus {outline: none;}
		&:hover {
		-webkit-filter: brightness(115%);
		filter: brightness(115%);
	}
	}
}
