@import 'variables';
@import 'mixin';

/*-------- Blog --------*/
article.blog {
    margin-bottom: 30px;
    background-color: #fff;
	@include box-shadow (0px 0px 20px 0px rgba(0, 0, 0, 0.10));

    .post_info {
        padding: 20px 30px  30px 30px;
        position: relative;
        box-sizing: border-box;
        small {
            font-weight: 500;
            color: #999;
			@include fontSize(13px);
        }
        h2 {
            //@include fontSize(21px);
            font-size: 1.5rem;
            a {
                color: #333;
                &:hover {color: $color-1;}
            }
        }
        ul {
            margin: 0 -30px 0 -30px;
            padding: 20px 30px 0 30px;
            width: 100%;
			box-sizing: content-box;
            border-top: 1px solid $border-color-1;
            li {
                display: inline-block;
                position: relative;
                padding: 12px 0  0 50px;
                font-weight: 500;
                @include fontSize(12px);
                color: #999;
                .thumb {
                    width: 40px;
                    height: 40px;
                    overflow: hidden;
                    @include border-radius(50%);
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    img {
                        width: 40px;
                        height: auto;
						position:absolute;
						left:50%;
						top:50%;
						@include transform (translate(-50%, -50%));
                    }
                }
                &:last-child {
                    float: right;
                    padding-left: 0;
					line-height: 1;
					i {
						@include fontSize(14px);
						margin-right: 5px;
						position: relative;
						top:3px;
					}
                }
            }
             @include tablet {position: static; width: auto;}
        }
    }
}
/*Blog Sidebar*/
.alignleft {
	float: left;
	margin: 0 15px 10px 0;
	width: 80px;
	height: 80px;
	overflow: hidden;
	@include border-radius(3px);
	position: relative;
	img {
			width: auto;
			height: 80px;
			position: absolute;
			left: 50%;
			top: 50%;
			@include transform (translate(-50%, -50%));
		}
}
.comments-list {
	padding: 0;
	list-style: none;
	h3 {
		@include fontSize(14px);
		padding: 0 0 0;
		margin: 0;
		text-transform: capitalize;
		a {
			color: #555;
			&:hover {
				color: $color-1;
			}
		}
	}
	li {
		margin-bottom: 10px;
		display: table;
		width: 100%;
		&:last-child {
			margin-bottom: 0;
		}
	}
	small {
		color: #555;
	}
}
.widget-title {
	padding: 15px 0;
	margin-bottom: 20px;
	border-bottom: 1px solid $border-color-2;
	 h4 {
		padding: 0;
		margin: 0;
		font-weight: 500;
		line-height:$line-height-none;
		//@include fontSize(16px);
         font-size: 1.05rem;
	}
}
.widget {
	position: relative;
	display: block;
	margin-bottom: 15px;
	ul.cats {
		list-style: none;
		padding: 0;
		li {
			padding: 0 0 5px 2px;
			position: relative;
		}
		a {
			color: #555;
            font-size: 1.05rem !important;
			&:hover {
				color: $color-1;
			}
			span {
				position: absolute;
				right: 0;
			}
		}
	}
}
.tags a {
	background-color: #f0f0f0;
	padding: 3px 10px;
	font-size: 13px;
	margin: 0 0 4px;
	letter-spacing: 0.4px;
	@include border-radius(3px);
	display: inline-block;
	color: #333;
	&:hover {
		background-color: $color-1;
		color: #fff;
	}
}

.search_blog {
	.form-group {
		position: relative;
		button[type="submit"] {
			outline: none;
			color: #444;
			@include fontSize(21px);
			background: none;
			border: 0;
			position: absolute;
			top: 5px;
			right: 0;
			&:hover {
				color: $color-1;
			}
		}
	}
}

/*Blog Post*/
.singlepost {
	background-color: #fff;
	@include border-radius(3px);
	padding: 30px 30px 1px 30px;
	margin-bottom: 25px;
	@include box-shadow (0px 0px 30px 0px rgba(0, 0, 0, 0.10));
	figure {
		margin:-30px -30px 30px -30px;
	}
	h1 {
		/*@include fontSize(28px);
		@include mobile {@include fontSize(21px);}
        font-size: 1.5rem;*/
	}

    h2 {
        /*@include fontSize(28px);
        @include mobile {@include fontSize(21px);}
        font-size: 1.5rem;*/
    }

    @media (min-width: 1024px) {
        p {
            text-align: justify;
            padding-right: 20px;
            padding-left: 20px;
        }

        .articleH1 {
            padding-right: 20px;
            padding-left: 20px;
        }

        .articleH2 {
            padding-right: 20px;
            padding-left: 20px;
        }

        .faq h3, .post-content h3, .faq h4, .post-content h4 {
            padding-right: 20px;
            padding-left: 20px;
        }
    }

	p {
		line-height: $line-height-large;
        font-size: 1.05rem !important;
	}

    td {
        line-height: $line-height-large;
        font-size: 1.05rem !important;
    }

    th {
        line-height: $line-height-large;
        font-size: 1.05rem !important;
    }

    li {
        line-height: $line-height-large;
        text-align: inherit;
        font-size: 1.05rem !important;
    }

    a {
        text-decoration: underline;
        color: $color-2;
    }

    a:hover {
        color: $color-4;
    }
}
.postmeta {
	padding-bottom: 10px;
	ul {
		padding: 0;
		margin: 0;
		margin-bottom: 10px;
		li {
			display: inline-block;
			margin:0 15px 5px 0;
			color: #777;
			i {
					margin-right: 3px;
				}
			a {
				color: #777;
				&:hover {
					color: $color-1;
				}
				i {
					margin-right: 3px;
				}
			}
		}
	}
}
.dropcaps p:first-child::first-letter {
	color: #fff;
	background-color: $color-1;
	float: left;
	@include fontSize(64px);
	line-height: 1;
	margin: 10px 15px 0 0 !important;
	padding: 12px;
}
#comments {
	padding: 10px 0 0px 0;
	margin-bottom: 30px;
	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		li {
			padding: 25px 0 0 0;
			list-style: none;
		}
	}
	.replied-to {
		margin-left: 35px;
		@include mobile {
			margin-left: 20px;
		}
	}
}
.avatar {
	float:left;
	margin-right:25px;
	width:68px;
	height:68px;
	overflow:hidden;
	@include border-radius(50%);
	position:relative;
	@include mobile {
		float:none;
		margin: 0 0 5px 0;
	}
	img {
		width:68px;
		height:auto;
		position:absolute;
		left:50%;
		top:50%;
		@include transform (translate(-50%, -50%));
	}
}
.comment_right {
	display:table;
	background-color: #fff;
	@include border-radius(3px);
	padding:20px 20px 0 20px;
	position:relative;
}

.comment_right:after, .comment_right:before {
	right: 100%;
	top: 30px;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	@include border-radius(3px);
}

.comment_right:after {
	border-color: rgba(255, 255, 255, 0);
	border-right-color: #fff;
	border-width: 15px;
	margin-top: -15px;
}
.comment_right:before {
	border-color: rgba(0, 0, 0, 0);
	border-width: 16px;
	margin-top: -16px;
}

.comment_info {
	padding-bottom:7px;
	span {padding:0 10px;}
}
