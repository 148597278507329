@import 'variables';
@import 'mixin';

/*============================================================================================*/
/* PRODUCT PAGE */
/*============================================================================================*/
.countdown_inner {
    background: #F33;
    color: #fff;
    margin-bottom: 30px;
    padding: 5px;
    text-align: center;
    font-weight: 500;

    @include mobile {
        margin-bottom: 15px;
    }

    &.version_2 {
        padding: 10px 5px;
        position: relative;

        &:before {
            border-style: solid solid solid none;
            border-width: 15px 15px 15px 0;
            bottom: -26px;
            content: "";
            left: 0;
            position: absolute;
            width: 15px;
            border-color: #F33 transparent transparent;

            @include tablet {
                display: none;
            }
        }

        strong {
            display: block;
            @include fontSize(28px);
            font-weight: 900;
            line-height: 1.2;
        }
    }

    .countdown {
        position: static;
        @include fontSize(16px);
        width: auto;
        margin: 0;
        display: inline-block;

        &:before {
            display: none;
        }
    }
}

/*-------- Carousel with thumbs --------*/
.all {
    width: 100%;
    height: auto;

    .slider,
    .slider-two {

        .owl-nav,
        .owl-dots {
            display: none;
        }

        width: auto;
        height: auto;
        overflow: hidden;
        position: relative;

        &:hover {

            .left,
            .right {
                visibility: visible;
                opacity: 1;
            }

            .left {
                left: 20px;
            }

            .right {
                right: 20px;
            }
        }

        .item-box {
            overflow: hidden;
            width: 100%;
            height: 450px;
            position: relative;
            background-size: cover;
            background-position: center center;

            @include mobile {
                height: 350px;
            }
        }

        .left,
        .right {
            position: absolute;
            z-index: 1;
            top: 50%;
            margin-top: -20px;
            width: 40px;
            height: 40px;
            cursor: pointer;
            visibility: hidden;
            opacity: 0;
            @include rgba(bg, #000, 0.5);
            @include border-radius(50%);
            text-align: center;
            line-height: 42px;
            @include fontSize(18px);
            color: #fff;

            &:hover {
                visibility: visible;
                opacity: 1;
            }
        }

        .left {
            left: -20px;
            transition: left ease .25s;

            &:hover {
                left: 20px;
            }
        }

        .left.nonl,
        .right.nonr {
            opacity: 0;
            visibility: hidden;
        }

        .left.nonl {
            left: -20px;
        }

        .right {
            right: -20px;
            transition: right ease .25s;

            &:hover {
                right: 20px;
            }
        }

        .right.nonr {
            right: -20px;
        }
    }

    .slider-two {
        &:hover {
            .left-t {
                visibility: visible;
                opacity: 1;
                left: 0;
            }

            .right-t {
                visibility: visible;
                opacity: 1;
                right: 0;
            }
        }

        .item {
            transition: all ease .25s;
            height: 100px;
            border: solid 2px #e6ecf2;
            cursor: pointer;
            background-size: cover;
            background-position: center center;
        }

        .item.active {
            border: solid 2px #ccc;
        }

        .owl-stage {
            padding: 20px 0;
        }

        .left-t,
        .right-t {
            cursor: pointer;
            position: absolute;
            z-index: 1;
            top: 20px;
            visibility: hidden;
            opacity: 0;
            transition: all ease .25s;
            width: 40px;
            height: 99px;
            background: no-repeat rgba($color-1, 0.85) url(../img/arrow_right.svg) center center;

            &:hover {
                visibility: visible;
                opacity: 1;
            }
        }

        .left-t {
            transform: rotate(180deg);
            left: -40px;

            &:hover {
                left: 0;
            }
        }

        .left-t.nonl-t,
        .right-t.nonr-t {
            opacity: 0;
            visibility: hidden;
        }

        .left-t.nonl-t {
            left: -40px;
        }

        .right-t {
            &:hover {
                right: 0;
            }
        }

        .right-t.nonr-t {
            right: -40px;
        }
    }
}

.prod_info {
    border-bottom: 1px dashed $border-color-1;
    padding-bottom: 25px;

    @include mobile {
        border-bottom: none;
        padding-bottom: 15px;
    }

    h1 {
        @include fontSize(24px);
        margin: 10px 0 2px 0;
    }

    .rating {
        margin-bottom: 10px;
    }

    p {
        margin-bottom: 20px;
    }

    small {
        font-weight: 600;
    }

    &.version_2 {
        border-bottom: none;
    }
}

.prod_options {
    padding: 20px 0 20px 0;

    @include tablet {
        padding: 0 0 20px 0;
    }

    label {
        padding-top: 5px;
    }

    .nice-select {
        border: 1px solid $border-color-2;
        height: 40px;
        line-height: 34px;
        padding-left: 10px;

        &:after {
            right: 15px;
        }
    }

    strong {
        text-transform: uppercase;
        font-weight: 500;
        line-height: 36px;
    }


    .colors {
        ul {
            margin: 0;
            padding: 0;

            li {
                display: inline-block;

                margin-right: 5px;

                a.color {
                    @include border-radius(50%);
                    display: block;
                    width: 28px;
                    height: 28px;
                    position: relative;

                    &:hover {
                        @include transform (scale(1.1));
                    }

                    &.active {
                        &:hover {
                            @include transform (scale(1));
                        }

                        &:before {
                            font-family: 'themify';
                            content: "\e64c";
                            color: #fff;
                            @include fontSize(13px);
                            line-height: 1;
                            position: absolute;
                            font-weight: bold;
                            top: 8px;
                            left: 8px;
                        }
                    }

                    &.color_1 {
                        background-color: #ffcc00;
                    }

                    &.color_2 {
                        background-color: #ff9933;
                    }

                    &.color_3 {
                        background-color: #ff9966;
                    }

                    &.color_4 {
                        background-color: #ff9999;
                    }

                    &.color_5 {
                        background-color: #ffcc00;
                    }
                }
            }
        }
    }

    &.version_2 {
        border: none;
    }
}

.price_main {

    @include mobile {
        text-align: right;
    }

    line-height: 1;
    padding-top: 10px;

    @include tablet {
        margin-bottom: 10px;
        padding-top: 0;
    }

    @include extra-small-mobile {
        margin-bottom: 0;
    }

    .old_price {
        font-size: 18px!important;
        font-size: 1rem!important;
    }

    .new_price {
        @include fontSize(24px);

    }

    .percentage {
        top: -2px;
    }
}

.btn_add_to_cart {

    @include mobile {
        text-align: right;
    }

    a.btn_1 {
        text-transform: uppercase;
        width: 100%;

        @include mobile {
            display: inline-block;
            width: auto;
            margin-top: 30px;
        }
    }
}

.nav-link {
    display: block;
    padding: 0 15px;
    height: 30px;
    @include fontSize(15px);
    color: #444;
}

.nav-link:hover,
.nav-link:focus {
    text-decoration: none;
}

.nav-link.disabled {
    color: #6c757d;
}

.nav-tabs {
    border: none;
    border-bottom: 2px solid #dee2e6;
}

.nav-tabs .nav-item {
    margin-bottom: -2px;
}

.nav-tabs .nav-link {
    border: none;
}

.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    border-bottom: 2px solid $color-1;
    color: $color-1;
    background-color: transparent;
}

.tabs_product {
    text-align: center;
    margin-top: 30px;

    @include mobile {
        margin-top: -30px;
        padding-top: 0
    }

    .nav-tabs {
        border: none;
        border-bottom: 1px solid transparent;
        display: flex;

        @include mobile {
            display: none;
        }

        .nav-item {
            text-transform: uppercase;
            font-weight: 500;
        }
    }

    &.version_2 {
        margin-top: 0;

        .nav-tabs {
            justify-content: center;
            flex-direction: row;
        }
    }

    &.version_3 {
        margin-top: 0;
        position: relative;

        .nav-tabs {

            @include mobile {
                display: flex;
            }
        }
    }
}

.product_actions ul {
    margin: 15px 0 0 2px;
    padding: 0;
    list-style: none;

    @include mobile {
        display: none;
    }

    li {
        float: left;
        margin-right: 25px;

        @include mobile {
            margin: -5px 10px 0 0;

            &:first-child i,
            &:nth-child(2) i {
                @include mobile {
                    @include fontSize(21px)
                }
            }
        }

        a {
            color: $body-text-color;

            &:hover {
                color: $color-1;
            }

            i {
                margin-right: 5px;
            }

            span {
                @include mobile {
                    display: none;
                }
            }
        }
    }
}

.tab_content_wrapper {
    background-color: #f8f8f8;
    padding: 60px 0 35px 0;

    @include tablet {
        padding: 30px 0 10px 0;
    }

    @include mobile {
        background-color: #fff;
        border-bottom: 1px solid $border-color-2;
        padding: 15px 0;
    }

    .card {
        border: none;
        background-color: transparent;

        .card-header {

            margin: 0 -15px;
            padding: 0;
            background-color: transparent;
            border: 0;
            border-top: 1px solid $border-color-2;
            background-color: #f8f8f8;

            a {
                display: block;
                @include fontSize(18px);
                padding: 15px;
                color: $heading-color;
            }
        }

        .card-body {
            padding: 0;

            h3 {
                @include fontSize(18px);
                margin-bottom: 20px;
                font-weight: 400;
            }
        }
    }
}

@media(min-width:768px) {

    .tabs_product .nav-tabs {
        display: flex;
    }

    .tab_content_wrapper .card {
        border: none;
    }

    .tab_content_wrapper .card .card-header {
        display: none;
    }

    .tab_content_wrapper .card .collapse {
        display: block;
    }
}




@media(max-width:767px) {
    .tab_content_wrapper .fade:not(.show) {
        opacity: 1;
    }

    .tab_content_wrapper .tab-pane {
        display: block !important;
        opacity: 1;
    }

    .tab_content_wrapper .card .card-header {
        margin-bottom: 15px;
        border: 0;

        a {
            &:after {
                font-family: themify;
                width: 20px;
                display: block;
                float: right;
            }

            &.collapsed {
                &:after {
                    content: "\e61a";
                }
            }

            &:after {

                content: "\e622";
            }
        }
    }
}


.review_content {
    margin-bottom: 30px;

    h4 {
        @include fontSize(18px);
    }

    .rating {
        color: #999;
        @include fontSize(12px);
        margin-bottom: 0;

        i {
            width: 20px;
            @include border-radius(3px);
            height: 20px;
            @include fontSize(12px);
            display: inline-block;
            background-color: #fec348;
            color: #fff;
            line-height: 20px;
            text-align: center;
            margin-right: 2px;

            &.empty {
                background-color: #ccc;
            }
        }

        float: left;

        @include extra-small-mobile {
            float: none;
            display: block;
        }

        em {
            @include extra-small-mobile {
                float: none;
                display: inline-block;
                margin-left: 10px;
            }
        }
    }

    em {
        font-weight: 500;
        color: #999;
        line-height: 22px;
        margin-left: 5px;
        float: right;

        @include extra-small-mobile {
            float: none;
            display: block;
            margin-top: 10px;
            margin-left: 0;
        }
    }

}

.table.sizes {

    td,
    tr th {
        white-space: nowrap;
    }
}
