@import 'variables';
@import 'mixin';
/*============================================================================================*/
/* ACCOUNT PAGE */
/*============================================================================================*/
.box_account {
    margin-bottom: 25px;
    h3 {
        @include fontSize(18px);
        padding-left: 30px;
        height: 30px;
        padding-top: 5px;
        display: inline-block;
		color: $body-text-color;
        &.client {
            background: url(../img/user.svg) center left no-repeat;
        }
        &.new_client {
            background: url(../img/new_user.svg) center left no-repeat;
        }
        }
    .form_container {
        background-color: #fff;
		@include box-shadow (0px 0px 10px 0px rgba(0, 0, 0, 0.10));
        padding: 25px;
        position: relative;
        hr {
            margin: 0 0 10px 0;
			border-color: $border-color-1;
        }
        a.social_bt {
            margin-bottom: 0;
            min-width: inherit;
            @include tablet {margin-bottom: 5px;}
        }
        .form-group {
            margin-bottom: 10px;
        }
        #forgot_pw{
           height: 100%!important;
            min-height: inherit;
           background-color: $bg-color-1;
           padding: 15px;
        }
    }
}