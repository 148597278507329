//-------------------- Base Colors --------------------//
$body-color: #fff;
$body-text-color: #444;
$body-text-color-2: #637381;
$heading-color: #505050;

$color-2: #083487;
$color-3: #32a067;
$color-4: #0e62ff;
$color-5: #FFC107;
$color-6: #ffff33;
$color-7: #ff5353;
$color-8: #fdfaf1;

$border-color-1: #ededed;
$border-color-2: #dddddd;


//-------------------- Line Height Variables --------------------//
$line-height-base: 1.5;
$line-height-large: 1.5;
$line-height-none: 1;

//-------------------- Font Families --------------------//
//$font-main: 'Roboto', Arial, Helvetica, sans-serif;
$font-main: Nunito,sans-serif;


//-------------------- Font Sizes --------------------//
$font-size-base: 0.875rem;


//-------------------- Responsive sizes -------------------- //
$large-device-width: 1199px; // Large devices (desktops, less than 1200px)
$medium-device-width: 991px; // Medium devices (tablets, less than 992px)
$small-device-width: 767px; // Small devices (landscape phones, less than 768px)
$extra-small-device-width: 575px; // Extra small devices (portrait phones, less than 576px)
