a {
    color: $blue;
    text-decoration: none;
    background-color: transparent;
}
a:hover {
    color: $blue;
    text-decoration: underline;
}

a:not([href]) {
    color: inherit;
    text-decoration: none;
}
a:not([href]):hover {
    color: inherit;
    text-decoration: none;
}

.collapse:not(.show) {
    display: none;
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
    text-decoration: none;
}

.sidenav {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex-wrap: nowrap;
    font-size: 0.9rem;
}
.sidenav .sidenav-menu {
    flex-grow: 1;
}
.sidenav .sidenav-menu .nav {
    flex-direction: column;
    flex-wrap: nowrap;
}
.sidenav .sidenav-menu .nav .sidenav-menu-heading {
    padding: 1.75rem 1rem 0.75rem;
    font-size: 0.7rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}
.sidenav .sidenav-menu .nav .nav-link {
    display: flex;
    align-items: center;
    line-height: normal;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    position: relative;
}
.sidenav .sidenav-menu .nav .nav-link .nav-link-icon {
    font-size: 0.9rem;
    padding-right: 0.5rem;
    display: inline-flex;
}
.sidenav .sidenav-menu .nav .nav-link .nav-link-icon .feather {
    width: 1rem;
    height: 1rem;
}
.sidenav .sidenav-menu .nav .nav-link.active {
    font-weight: 600;
}
.sidenav .sidenav-menu .nav .nav-link.active .nav-icon {
    color: $blue;
}
.sidenav .sidenav-menu .nav .nav-link .sidenav-collapse-arrow {
    display: inline-block;
    margin-left: auto;
    transition: transform 0.15s ease;
}
.sidenav .sidenav-menu .nav .nav-link.collapsed .sidenav-collapse-arrow {
    transform: rotate(-90deg);
}
.sidenav .sidenav-menu .nav .sidenav-menu-nested {
    flex-direction: column;
    margin-left: 1.4375rem;
    border-left-style: solid;
    border-left-width: thin;
    padding-left: 0.5625rem;
}

.sidenav-dark .sidenav-menu .sidenav-menu-heading {
    color: rgba(255, 255, 255, 0.25);
}
.sidenav-dark .sidenav-menu .nav-link {
    color: rgba(255, 255, 255, 0.5);
}
.sidenav-dark .sidenav-menu .nav-link .nav-link-icon {
    color: rgba(255, 255, 255, 0.25);
}
.sidenav-dark .sidenav-menu .nav-link .sidenav-collapse-arrow {
    color: rgba(255, 255, 255, 0.25);
}
.sidenav-dark .sidenav-menu .nav-link:hover {
    color: #fff;
}
.sidenav-dark .sidenav-menu .nav-link.active {
    color: #fff;
}
.sidenav-dark .sidenav-menu .nav-link.active .nav-link-icon {
    color: #fff;
}
.sidenav-dark .sidenav-menu-nested {
    border-left-color: rgba(255, 255, 255, 0.15);
}

.sidenav-light {
    color: #1f2d41;
}
.sidenav-light .sidenav-menu .sidenav-menu-heading {
    color: #a2acba;
}
.sidenav-light .sidenav-menu .nav-link {
    color: #1f2d41;
}
.sidenav-light .sidenav-menu .nav-link .nav-link-icon {
    color: #a2acba;
}
.sidenav-light .sidenav-menu .nav-link .sidenav-collapse-arrow {
    color: #a2acba;
}
.sidenav-light .sidenav-menu .nav-link:hover {
    color: $blue;
}
.sidenav-light .sidenav-menu .nav-link.active {
    color: $blue;
}
.sidenav-light .sidenav-menu .nav-link.active .nav-link-icon {
    color: $blue;
}
.sidenav-light .sidenav-menu-nested {
    border-left-color: #d7dce3;
}
.sidenav-light {

}
.sidenav-light {
    color: #687281;
}
